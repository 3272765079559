@font-face {
  font-family: "iconfont"; /* Project id 3325204 */
  src: url('iconfont.woff2?t=1670212500631') format('woff2'),
       url('iconfont.woff?t=1670212500631') format('woff'),
       url('iconfont.ttf?t=1670212500631') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-yingyongTokens:before {
  content: "\e783";
}

.icon-WebHooks:before {
  content: "\e782";
}

.icon-enable:before {
  content: "\e77e";
}

.icon-user:before {
  content: "\e77f";
}

.icon-percentage:before {
  content: "\e780";
}

.icon-group:before {
  content: "\e781";
}

.icon-timeout:before {
  content: "\e77d";
}

.icon-service:before {
  content: "\e77b";
}

.icon-lock:before {
  content: "\e77a";
}

.icon-toggle:before {
  content: "\e779";
}

.icon-reject:before {
  content: "\e775";
}

.icon-pending:before {
  content: "\e776";
}

.icon-wait:before {
  content: "\e777";
}

.icon-withdraw:before {
  content: "\e778";
}

.icon-connect-sdk:before {
  content: "\e773";
}

.icon-setting:before {
  content: "\e774";
}

.icon-filter:before {
  content: "\e772";
}

.icon-info:before {
  content: "\e771";
}

.icon-back:before {
  content: "\e770";
}

.icon-menu:before {
  content: "\e76a";
}

.icon-email:before {
  content: "\e766";
}

.icon-check-circle:before {
  content: "\e764";
}

.icon-edit:before {
  content: "\e75f";
}

.icon-view:before {
  content: "\e75e";
}

.icon-search:before {
  content: "\e753";
}

.icon-put-up:before {
  content: "\e752";
}

.icon-put-away:before {
  content: "\e751";
}

.icon-password:before {
  content: "\e750";
}

.icon-reduce:before {
  content: "\e74f";
}

.icon-more:before {
  content: "\e74e";
}

.icon-angle-down:before {
  content: "\e74d";
}

.icon-angle-left:before {
  content: "\e74b";
}

.icon-angle-up:before {
  content: "\e74c";
}

.icon-angle-right:before {
  content: "\e74a";
}

.icon-avatar:before {
  content: "\e749";
}

.icon-english:before {
  content: "\e747";
}

.icon-info-circle:before {
  content: "\e743";
}

.icon-warning-circle:before {
  content: "\e744";
}

.icon-error-circle:before {
  content: "\e745";
}

.icon-success-circle:before {
  content: "\e746";
}

.icon-remove-circle:before {
  content: "\e742";
}

.icon-code:before {
  content: "\e741";
}

.icon-add:before {
  content: "\e740";
}

.icon-close:before {
  content: "\e73f";
}

.icon-check:before {
  content: "\e73d";
}

.icon-question:before {
  content: "\e73c";
}

.icon-archive:before {
  content: "\e73a";
}

.icon-drag:before {
  content: "\e73b";
}

.icon-minus:before {
  content: "\e739";
}

.icon-evaluate:before {
  content: "\e738";
}

.icon-member:before {
  content: "\e737";
}

.icon-attribute:before {
  content: "\e735";
}

.icon-switch:before {
  content: "\e733";
}

.icon-debugger:before {
  content: "\e734";
}

