.header {
	height: 48px;
	line-height: 48px;
	padding: 0 20px 0 10px;
	display: flex;
	background: #ffffff;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.01), 0 3px 6px 3px rgba(0, 0, 0, 0.01), 0 2px 6px 0 rgba(0, 0, 0, 0.03);

  :global {
    .navs-item {
      text-align: center;
      cursor: pointer;
      font-size: 16px;
      color: #495057;
      position: relative;
      margin: 0 18px;
      white-space: pre;
    }
    
    .navs-item-selected {
      border-bottom: 2px solid #556ee6;
      color: #556ee6;
      height: 43px;
      font-family: PingFangSC-Medium;
      cursor: pointer;
    }

    .user {
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #495057;
      cursor: pointer;
    }

    .user-circle {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background: #556ee6;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      margin-right: 12px;
      vertical-align: middle;
    }
  }
}

.logo {
	font-family: HelveticaNeue-Bold;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.84);
  cursor: pointer;
}

.logo-image {
  margin-top: 7px;
}

.navs {
	flex: 1;
	display: flex;
	margin-left: 82px;
}

.popup {
  padding: 4px 0 !important;
  margin-top: 0 !important;
}

.language-popup {
  margin-right: 32px;
  white-space: pre;
}

.angle-down {
  position: absolute;
}

.question-popup {
  display: flex;
  margin-right: 24px;
  align-items: center;
}

.question {
  font-size: 24px !important;
  vertical-align: middle;
}

.menu {
  min-width: 120px;
  color: #212529;
}

.menu-item {
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  font-size: 12px;
  padding: 0 12px;
}

.menu-item:hover {
  background-color: #f8f9fa;
}

.github {
  width: 24px;
  height: 24px;
  margin-right: 25px;
  vertical-align: middle;
}

.count {
  height: 20px;
  min-width: 20px;
  background: #f5483b;
  border: 1px solid #ffffff;
  border-radius: 10px;
  display: inline-block;
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #ffffff;
  line-height: 20px;
  padding: 0 5px;
  position: absolute;
  top: 5px;
  right: -13px;
  text-align: center;
}

.user-container {
  white-space: pre;
}

.header-main {
	background: #556ee6;
  color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.01);

  :global {
    .navs-item {
      color: #ffffff !important;
      text-align: center;
      cursor: pointer;
      font-size: 16px;
      margin-right: 35px;
      opacity: 0.7;
      padding: 0 12px;
    }

    .navs-item-selected {
      border-bottom: 2px solid #ffffff;
      color: #ffffff;
      height: 43px;
      font-family: PingFangSC-Medium;
      cursor: pointer;
      opacity: 1;
    }

    .user-circle {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background: #ffffff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #495057;
      margin-right: 12px;
      vertical-align: middle;
    }

    .user {
      font-size: 14px;
      color: #ffffff;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}
